import {
    getRequest,
    postRequest,
    // putRequest,
    // deleteRequest,
    // postRequestFormData,
    postRequestadFormData,
} from "../coreFIles/helper";


export const LoginAction = (data) => {
    return postRequest("memberLogin", data).then((res) => {
        return res.data;
    });
};
export const SendForgotPasswordMailAction = (data) => {
    return postRequest("sendForgotPasswordMail", data).then((res) => {
        return res.data;
    });
};
export const SaveUserForgotPasswordAction = (data) => {
    return postRequest("saveUserForgotPassword", data).then((res) => {    
        return res.data;        
    });
};

export const getMemberProfilesAction = (data) => {
  return postRequest('getMemberProfile', data).then(res => { return res.data })
}

export const getMemberDetailsAction = (data) => {
  return postRequest('getMemberDetails', data).then(res => { return res.data })
}

export const updateMemberProfileAction = (data) => {
  return postRequest('updateMemberProfile', data).then(res => { return res.data })
}

export const updateMemberpasswordAction = (data) => {
  return postRequest('updateMemberpassword', data).then(res => { return res.data })
}


export const getCategoriesAction = (data) => {
    return postRequest("getCategories", data).then((res) => {
        return res.data;
    });
};

export const getFooterCategoriesAction = (data) => {
    return postRequest("getFooterCategories", data).then((res) => {
        return res.data;
    });
};

export const latestAdsAction = (data) => {
    return postRequest("latestAds", data).then((res) => {
        return res.data;
    });
};

export const featuredAdsAction = (data) => {
    return postRequest("featuredAds", data).then((res) => {
        return res.data;
    });
};

export const homeSlidersAction = (data) => {
    return getRequest("homeSliders", data).then((res) => {
        return res.data;
    });
};

export const homeHozAdsAction = (data) => {
    return getRequest("homeHozAds", data).then((res) => {
        return res.data;
    });
};

export const getnavChildCategoriesAction = (data) => {
    return postRequest("getnavChildCategories", data).then((res) => {
        return res.data;
    });
};

export const getStatesAction = (data) => {
    return postRequest("getAllStates", data).then((res) => {
        return res.data;
    });
};

export const getSearchSuburbAction = (data) => {
    return postRequest("getSrchSuburb", data).then((res) => {
        return res.data;
    });
};

export const getSearchAdtitleAction = (data) => {
    return postRequest("getSearchTitle", data).then((res) => {
        return res.data;
    });
};

export const getCategoryChildsAction = (data) => {
    return postRequest("getCategoryChilds", data).then((res) => {
        return res.data;
    });
};

export const getAllCatChildsAction = (data) => {
    return getRequest("getAllCatChilds", data).then((res) => {
        return res.data;
    });
};

export const getChildCatsCountAction = (data) => {
    return getRequest("getChildCatsCount", data).then((res) => {
        return res.data;
    });
};

export const getContentByKeywordAction = (data) => {
    return postRequest("getContentByKeyword", data).then((res) => {
        return res.data;
    });
};

export const getfooterMenuAction = (data) => {
    return getRequest("getfooterMenu", data).then((res) => {
        return res.data;
    });
};

export const getsidebarMenuAction = (data) => {
    return getRequest("getsidebarMenu", data).then((res) => {
        return res.data;
    });
};

export const getbannerAdsAction = (data) => {
    return getRequest("getbannerAds", data).then((res) => {
        return res.data;
    });
};

export const getadDataAction = (data) => {
    return postRequest("getadData", data).then((res) => {
        return res.data;
    });
};

export const getadImgesAction = (data) => {
    return postRequest("getadImges", data).then((res) => {
        return res.data;
    });
};

export const deladimgAction = (data) => {
    return postRequest("deladimg", data).then((res) => {
        return res.data;
    });
};

export const getRelatedAdsAction = (data) => {
    return postRequest("getRelatedAds", data).then((res) => {
        return res.data;
    });
};

export const catDetailsAction = (data) => {
    return postRequest("catDetails", data).then((res) => {
        return res.data;
    });
};

/* all ads */
export const categoryAlladsAction = (data) => {
    return postRequest("categoryAllads", data).then((res) => {
        return res.data;
    });
};

export const categoryAlladsCountAction = (data) => {
    return postRequest("categoryAlladsCount", data).then((res) => {
        return res.data;
    });
};

/* all ads */

/* cat ads */
export const categoryadsAction = (data) => {
    return postRequest("categoryads", data).then((res) => {
        return res.data;
    });
};

export const categoryadsCountAction = (data) => {
    return postRequest("categoryadsCount", data).then((res) => {
        return res.data;
    });
};

/* cat ads */


/* search ads */
export const SearchAlladsAction = (data) => {
    return postRequest("SearchAllads", data).then((res) => {
        return res.data;
    });
};

export const SearchAlladsCountAction = (data) => {
    return postRequest("SearchAlladsCount", data).then((res) => {
        return res.data;
    });
};
// searchHistory
export const SaveSearchHistoryAction=(data)=>{
    return postRequest("SaveSearchHistory",data).then((res)=>{
        return res.data;
    })
}
export const getSaveSearchHistoryAction=(data)=>{
    return postRequest("SearchHistory",data).then((res)=>{
        return res.data;
    })
}
// saveFavouritesAds
export const SaveFavouriteAdsAction=(data)=>{
    return postRequest("MarkfavouriteAd",data).then((res)=>{
        return res.data;
    })
}
// getting favourites ads
export const getFavouriteAdsAction=(data)=>{
    return postRequest("GetFavouritsAds",data).then((res)=>{
        return res.data
    })
}

/* search ads */



export const categoryPreadsAction = (data) => {
    return postRequest("categoryPreads", data).then((res) => {
        return res.data;
    });
};

export const categoryFreeadsAction = (data) => {
    return postRequest("categoryFreeads", data).then((res) => {
        return res.data;
    });
};

export const premiumAdsCountAction = (data) => {
    return postRequest("premiumAdsCount", data).then((res) => {
        return res.data;
    });
};

export const freeAdsCountAction = (data) => {
    return postRequest("freeAdsCount", data).then((res) => {
        return res.data;
    });
};

export const parentCatDetailsAction = (data) => {
    return postRequest("parentCatDetails", data).then((res) => {
        return res.data;
    });
};

export const categoryAllPreadsAction = (data) => {
    return postRequest("categoryAllPreads", data).then((res) => {
        return res.data;
    });
};

export const categoryAllFreeadsAction = (data) => {
    return postRequest("categoryAllFreeads", data).then((res) => {
        return res.data;
    });
};

export const premiumAdsAllCountAction = (data) => {
    return postRequest("premiumAdsAllCount", data).then((res) => {
        return res.data;
    });
};

export const freeAdsAllCountAction = (data) => {
    return postRequest("freeAdsAllCount", data).then((res) => {
        return res.data;
    });
};

export const getCountriesAction = (data) => {
    return postRequest("getCountries", data).then((res) => {
        return res.data;
    });
};

export const createMemberAction = (data) => {
    return postRequest("createMember", data).then((res) => {
        return res.data;
    });
};

export const verifyMemberAction = (data) => {
    return postRequest("verifyMember", data).then((res) => {
        return res.data;
    });
};

export const getPlansAction = (data) => {
    return getRequest("getPlans", data).then((res) => {
        return res.data;
    });
};

export const getPlanDetailsAction = (data) => {
    return postRequest("getPlanDetails", data).then((res) => {
        return res.data;
    });
};

export const createAdAction = (data) => {
    return postRequestadFormData("createNewAd", data).then((res) => {
        return res.data;
    });
};

export const updateAdAction = (data) => {
    return postRequestadFormData("updateuserAd", data).then((res) => {
        return res.data;
    });
};
export const getAdDetailAction = (data) => {
    return postRequest("getuserAdDetail", data).then((res) => {
        return res.data;
    });
};

export const getAdPaymentHistoryAction = (data) => {
    return postRequest("getUserAdPaymentHistory", data).then((res) => {
        return res.data;
    });
};

export const getChildCatsAction = (data) => {
    return postRequest("getChildCategories", data).then((res) => {
        return res.data;
    });
};

export const getMemberAdsAction = (data) => {
    return postRequest("getMemberAds", data).then((res) => {
        return res.data;
    });
};

export const deleteadAction = (data) => {
    return postRequest("deletead", data).then((res) => {
        return res.data;
    });
};
export const repostAdsAction = (data) => {
    return postRequest("repostAds", data).then((res) => {
        return res.data;
    });
};
export const bulkdeleteadAction = (data) => {
    return postRequest("bulkdeletead", data).then((res) => {
        return res.data;
    });
};
export const adBlockUnBlockAction = (data) => {
    return postRequest("useradBlockUnBlock", data).then((res) => {
        return res.data;
    });
};

export const ContactusPostAction = (data) => {
    return postRequest("ContactusPost", data).then((res) => {
        console.log(res)
        return res.data;
    });
};
export const getContentAction = (data) => {
    return postRequest("getContent", data).then((res) => {
        return res.data;
    });
};

export const getMemberFavouriteAdsAction = (data) => {
    return postRequest("getMemberFavouriteAds", data).then((res) => {
        return res.data;
    });
};

// Tell A Friend Action
export const tellaFriendAction = (data) => {
    return postRequest("tellaFriend", data).then((res) => {
        return res.data;
    });
};

// Email To friend
export const sharewithfriendAction = (data) => {
    return postRequest("sharewithfriend", data).then((res) => {
        return res.data;
    });
};
// replyToclassifiedAction
export const replyToclassifiedAction = (data) => {
    return postRequestadFormData("replyToclassified", data).then((res) => {
        return res.data;
    });
};
// subscribe email
export const subscribemailAction = (data) => {
    return postRequest("subscribemail", data).then((res) => {
        return res.data;
    });
};

// report this ad
export const reportthisadAction = (data)=>{
    return postRequest("reportthisad",data).then((res)=>{
        return res.data
    })
}
// upgrade Plan 
export const upgradePlaneAction=(data)=>{
    return postRequest("upgradeplan",data).then((res)=>{
        return res.data
    })
}
// getting vipmember for content 
export const UpgradeAccountAction=(data)=>{
    return postRequest("UpgradeAccount",data).then((res)=>{
        return res.data
    })
}
export const gettingDomainImgAction =(data)=>{
    return postRequestadFormData("getdomainImage",data).then((res)=>{
       return res.data
    })
}
export const getdomainContentAction =(data)=>{
    return postRequest("getdomainContent",data).then((res)=>{
       return res.data
    })
}
export const getfooterdomainAction =(data)=>{
    return postRequest("getFooterdomainContent",data).then((res)=>{
       return res.data
    })
}
export const doaminLeatestAdsAction =(data)=>{
    return postRequest("doaminLeatestAds",data).then((res)=>{
       return res.data
    })
}
export const domainFeatuerAdsAction =(data)=>{
    return postRequest("domainFeatuerAds",data).then((res)=>{
       return res.data
    })
}
export const getDoaminStyleAction=(data)=>{
    return postRequest("getDoaminStyle",data).then((res)=>{
        return res.data
    })
}
export const getdomaincatdiscriptionAction=(data)=>{
    return postRequest("getdomcatdiscription",data).then((res)=>{
        return res.data
    })
}
// login with facebook
export const memberloginwithfacebookAction=(data)=>{
    return postRequest("memberloginwithfacebook",data).then((res)=>{
        return res.data
    })
}
