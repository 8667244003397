import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import config from "../coreFIles/config";
import Header from '../directives/header'
import Leftnav from '../directives/leftSidebar'
import Rightnav from '../directives/rightSidebar'
import Footer from '../directives/footer'
import Cookies from 'js-cookie'

import { SendForgotPasswordMailAction } from '../Action/action';
const Domainstyle = (!Cookies.get('domainstyle')) ? [] : JSON.parse(Cookies.get('domainstyle'));
const Domainid = Cookies.get('domain_id') ? JSON.parse(Cookies.get('domain_id')) : 0;

const ForgotPassword = () => {
	// style
	const timeColor = `#${Domainstyle[0]?.time_color}`;
	const headerButtonColor = `#${Domainstyle[0]?.header_buttoncolor}`;
	const headerMenuColor = `#${Domainstyle[0]?.header_menu}`;
	const headerMenuHoverColor = `#${Domainstyle[0]?.header_menu_hover}`;
	const header_search_text = `#${Domainstyle[0]?.header_search_text}`

	// style end
	const [form, setForm] = useState({ master_id: "3", domain_id: Domainid ? Domainid.domain_id : "0", email:''});

	const [validatioError, setvalidatioError] = useState({});

	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}

	function validate() {
		let emailError = "";
		if (form.email === '') 
			emailError = "Email is required."
		

		if (emailError) {
			setvalidatioError({
				emailError
			})

			return false
		} else {
			setvalidatioError({
				emailError
			})
			return true
		}
	}

	const send_FPM = async (e) => {
		e.preventDefault() 
		let valid = validate()
		if(!valid) return
		console.log("hello")
		let res = await SendForgotPasswordMailAction(form);
		
		if (res.success) {
			toast.success(res.msg);
			setTimeout(() => {
				window.location.href = `${config.baseUrl}login`;
			},4000);
		} else {
			toast.error(res.msg);
		}
	}


	return (
		<>
			<Header />

			<div className="add-section mb-3">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Leftnav />
						</div>
						<div className="col-lg-6">
							<Toaster />

							<div className="classification-section">
								<h2 className="page-heading" style={{ background: headerMenuColor }}>Member Forgot Password</h2>
								<form action='#'>
									<div className="bondiclassified-fromsection">
										<div className="catagoryfrom">
											<div className="member-login">
												<div className="row">
													<div className="col-md-12 col-sm-12 user">
														<img src={config.baseUrl + "img/user.png"} alt='img' />
													</div>
													<div className="col-md-12 col-sm-12">
														<div className="form-group">
															{/* <label for="memberemail">Email</label> */}
															<input type="text" className="form-control" name="email" id="email" placeholder="Enter Your Email" onChange={inputHandler} />
															<span className="text-danger">{validatioError.emailError}</span>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12 mt-3">
														<button type="submit" onClick={send_FPM} className="btn btn-primary login-btn" style={{ background: headerButtonColor }} >Submit</button>

													</div>
													<div className="col-md-12">
														<Link to={config.baseUrl + 'login'} className="btn btn-primary login-btn fb">Login</Link>

														<Link to={config.baseUrl + 'register'} className="btn btn-primary login-btn reg">Register Now</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>

							</div>
						</div>
						<div className="col-lg-3">
							<Rightnav />
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	)

}

export default ForgotPassword;
