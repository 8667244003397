import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import config from "../coreFIles/config";
import toast, { Toaster } from 'react-hot-toast';
import Header from '../directives/header'
import Leftnav from '../directives/leftSidebar'
import Rightnav from '../directives/rightSidebar'
import Footer from '../directives/footer'
import CustomPagination from './CustomPagination';
import Cookies from 'js-cookie'
import { catDetailsAction, categoryadsAction, categoryFreeadsAction, categoryadsCountAction, freeAdsCountAction, SaveFavouriteAdsAction, getFavouriteAdsAction, getMemberFavouriteAdsAction,getdomaincatdiscriptionAction } from '../Action/action';
const ageVerified = (!Cookies.get('ageVerified')) ? false : Cookies.get('ageVerified');
const loginData = (!Cookies.get('loginSuccessMember')) ? [] : JSON.parse(Cookies.get('loginSuccessMember'));

const Domainid = Cookies.get('domain_id') ? JSON.parse(Cookies.get('domain_id')) : 0;

const Domainstyle = (!Cookies.get('domainstyle')) ? [] : JSON.parse(Cookies.get('domainstyle'));
const Adslist = () => {
	// style
	const timeColor = `#${Domainstyle[0]?.time_color}`;
	const headerButtonColor = `#${Domainstyle[0]?.header_buttoncolor}`;
	const headerMenuColor = `#${Domainstyle[0]?.header_menu}`;
	const headerMenuHoverColor = `#${Domainstyle[0]?.header_menu_hover}`;
	const header_search_text = `#${Domainstyle[0]?.header_search_text}`
	// style end
	const { id } = useParams();
	const { any } = useParams();
	const [page, setPage] = useState(0);
	const [totalPages, settotalPages] = useState(0);
	const [totalRecords, settotalRecords] = useState(0);
	const [catDetails, setCatDetails] = useState({});
	const [content, setdisclaimerMSG] = useState('');
	const [catPreAds, setCatPreAds] = useState({});
	const [catFreeAds, setCatFreeAds] = useState({});
	const [adUrl, setAdUrl] = useState('');
	const [freeAds, setCountFreeAds] = useState('');
	const [premiumAds, setCountPreAds] = useState('');
	const [spinloader, setspinloader] = useState(0);
	const [isVerified, setIsVerified] = useState(false);
	const [pagingspinloader, setpagingspinloader] = useState(1);
	const [selectedFavoriteId, setSelectedFavoriteId] = useState(null);
	const [getfavAds, setgetfavAds] = useState([])
	const [dom_cat_dis, setdom_cat_dis] = useState([]);


	useEffect(() => {
		setspinloader(1)
		//getdisclaimerMSGAPI();
		getCatDetails();
		getCatAds();
		getdomaincatdiscriptionApi()
		getCountCatAds();
			if (!Cookies.get('loginSuccessMember')) {
			return 
		}else{
		GetFavoriteAds()
		}
		
		
	}, [])
	// for changing title
	const getdomaincatdiscriptionApi = async () => {
		let res = await getdomaincatdiscriptionAction({ cat_id: id, domain_id: Domainid.domain_id });

		if (res.success) {
			setdom_cat_dis(res.data);
		} else {
			setdom_cat_dis([]);
		}
	};
	
	// for getting your favourites ads
	const GetFavoriteAds = async () => {
		try {
			if(!loginData){
				return null
			}else{

			const res = await getMemberFavouriteAdsAction({ memberid: loginData?.id });

			if (res.success) {
				const adId = res.data;
				setgetfavAds(adId)

			} else {

				toast.error(`Failed to fetch favorite ads: ${res.error}`);
			}
		} }catch (error) {

			toast.error(`An error occurred while fetching favorite ads: ${error}`);
		}
	};



	// favouriate ads
	const handleFavorite = async (adId) => {
		if (!Cookies.get('loginSuccessMember')) {
			toast.error('Please login first.');
			return null;
		}
		try {
			let res = await SaveFavouriteAdsAction({
				memberid: loginData.id,
				adid: adId
			});
			if (res.success) {
				toast.success(res.msg);
				GetFavoriteAds()
			}
			else {
				toast.error(res.msg);
				GetFavoriteAds()
			}


		} catch (error) {
			console.error('Error saving favorite ad:', error);
			toast.error('Failed to save ad to favorites');
		}


	};

	const getCatDetails = async () => {
		let res = await catDetailsAction({ catId: id, any: any });
		if (res.success) {
			setCatDetails(res.data);
			console.log(res.data)
		}
		else if (any == 'action') {
			window.location.href = `${config.baseUrl + 'cat/' + id + '/action/all/' + any}`
		}
		else {
			window.location.href = `${config.baseUrl}`
		}
	};

	const getCatAds = async () => {
		let res = await categoryadsAction({ catId: id, page: page , domain_id: Domainid?.domain_id});
		if (res.success) {
			const freeAds = res.data.filter(item => item.is_free === 1);
			const paidAds = res.data.filter(item => item.is_free === 0);
			console.log(res.data)
			setCatPreAds(paidAds);
			setCatFreeAds(freeAds);
			setspinloader(0);
		}
	};

	const getCatFreeAds = async () => {
		let res = await categoryFreeadsAction({ catId: id });
		if (res.success) {
			setCatFreeAds(res.data);
		}
	};

	const getCountCatAds = async () => {
		settotalPages(0);
		settotalRecords(0);
		setpagingspinloader(0);
		let res = await categoryadsCountAction({catId: id,domain_id:Domainid.domain_id});
		if (res.success) {
			settotalPages(res.data);
			settotalRecords(res.totalRecords);
			setpagingspinloader(0);
		}
	};

	const onPageChanged = async (data) => {
		const { currentPage, totalPages, pageLimit } = data;
		const offset = (currentPage - 1) * pageLimit;
		let res = await categoryadsAction({ catId: id, page: offset, domain_id: Domainid?.domain_id  });
		if (res.success) {
			const freeAds = res.data.filter(item => item.is_free === 1);
			const paidAds = res.data.filter(item => item.is_free === 0);
			setCatPreAds(paidAds);
			setCatFreeAds(freeAds);
		}
		else {
			setCatPreAds({});
			setCatFreeAds({});
		}
	}

	const getCountCatFreeAds = async () => {
		let res = await freeAdsCountAction({ catId: id });
		if (res.success) {
			setCountFreeAds(res.data);
		}
		setspinloader(0)
	};

	// function to redirect to ad url on click ad title
	const handleAdUrl = (row) => {
		var pagenewUrl = '';
		pagenewUrl = config.baseUrl + 'ad/' + row.id + '/' + row.parent_cat.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.title.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();

		window.location.href = `${pagenewUrl}`;
	};

	// function to create ad url on hover ad title
	const getAdUrl = (row) => {
		var pagenewUrl = '';
		pagenewUrl = config.baseUrl + 'ad/' + row.id + '/' + row.parent_cat.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.title.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();

		setAdUrl(pagenewUrl);
	};

	const handleOver18 = () => {
		Cookies.set('ageVerified', 'true', { expires: 0.0208333 });	
		setTimeout(() => {
			window.location.reload();
		}, 2000);
	};

	const handleUnder18 = () => {
		var pagenewUrl = config.baseUrl;
		window.location.href = `${pagenewUrl}`;
	};
	
	return (
		<>
			<Header />
			<Toaster />
			<Helmet>
				<title>{dom_cat_dis[0]?.meta_title}</title>
				<meta
					name="description"
					content={dom_cat_dis[0]?.meta_description}
				/>
				<meta
					name="keywords"
					content={dom_cat_dis[0]?.meta_keyword}
				/>
			</Helmet>

			{catDetails.cat_desc != '' ? (

				<div className="product-single">
					<div className="container">
						{/* <div className="font-weight-bold mb-3" dangerouslySetInnerHTML={{ __html: catDetails?.cat_desc }}></div> */}

					</div>
				</div>
			) : (

				''
			)}
			<div className="add-section mb-3">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Leftnav />
						</div>
						<div className="col-lg-6">
							{parseInt(catDetails.parent_id) == parseInt(config.cat_per) && !ageVerified ? (
								<div className="buy-end-sell">
									<div className="generalMessage">
										<h3 className="text-center">Warning Explicit Material!</h3>
										<p>
											This is an adult oriented section of Bondi Classifieds which may contain images, language and other explicit material not suitable for children and teens under 18.<br /><br />
											By entering this site, you are certifying that you are of legal adult age and are therefore liable for any ramifications, legal or otherwise, that may arise from your viewing and reading the contents herein.
										</p>
										<p>
											The act of entering this site indicates agreement on your part with all of the above statements.
										</p>
										<div className="text-center">
											<button type="button" onClick={handleOver18}>I'M OVER 18 LET ME IN</button>
											<button type="button" onClick={handleUnder18}>NO I'M NOT OVER 18</button>
										</div>
									</div>
								</div>
							) :
								spinloader === 0 ? (
									<>
										<div className="buy-end-sell">
											<h2 className="page-heading" style={{background:headerMenuColor}}>{catDetails?.cat_name}</h2>
											{catPreAds.length > 0 && (
												<div className="heading-super">
													<h6  style={{background:headerMenuColor}} >PREMIUM LISTINGS</h6>
												</div>
											)}
											{catPreAds.length > 0 && (
												<div id="premiumads" >
													{catPreAds.map((row, index) => (
														<div key={index} className={index % 2 == 0 ? 'section-listing' : 'section-listing section-listing-new'}>
															<div className="main-item">
																<div className="fast-block">
																	{row.addImg ? (
																		<img src={config.apiUrl + row.addImg} alt={row.title} />
																	) : (
																		<img src={config.baseUrl + "img/noImage.png"} alt={row.title + row.id} />
																	)}
																</div>
																<div className="second-block">
																	<Link className="fordhead" to="javascript:;" onClick={() => handleAdUrl(row)}>
																		{row.title}
																	</Link>
																	{row.sell_price != 0 && row.sell_price != '' && catDetails?.parent_id != config.cat_eat ? (
																		<>
																			<p><span><i className="fa-solid fa-dollar-sign"></i></span> ${row.sell_price}</p>
																		</>
																	) : ''
																	}
																	{parseInt(catDetails?.parent_id) == parseInt(config.cat_per) ? (
																		<>
																			<p><span><i class="fa-solid fa-user"></i></span>Age - Gender: {row.age} - {row.sex == 0 ? 'Male' : 'Female'}</p>
																		</>
																	) : parseInt(catDetails?.parent_id) == parseInt(config.cat_real) ? (
																		<>

																			<p><span><i class="fa-regular fa-building"></i></span>Property Type: {row.property_type}</p>
																			<p><span><i class="fa-solid fa-bed"></i></span>Bedrooms: {row.bed_rooms}</p>

																		</>
																	) : parseInt(catDetails?.parent_id) == parseInt(config.cat_whats) ? (
																		<>
																			<p><span><i class="fa-solid fa-calendar-days"></i></span>Date: {row.formatted_whatson_date}</p>
																			<p><span><i class="fa-regular fa-clock"></i></span>Time: {row.time_whatson}</p>

																		</>
																	) : parseInt(catDetails?.parent_id) == parseInt(config.cat_jobs) ? (
																		<>
																			<p><span><i class="fa-solid fa-briefcase"></i></span>Work type: {row.job_work_type}</p>

																		</>
																	) : (
																		''
																	)}

																	<p><span><i className="fa-solid fa-location-dot"></i></span> {row.suburb} ({row.area})</p>
																</div>

																{getfavAds.includes(row.id) ?
																	(<a
																		className="fevcls"
																		title=""
																		onClick={() => handleFavorite(row.id)}
																	>
																		<i className="fa fa-heart" aria-hidden="true"></i>
																	</a>)
																	:
																	(<a
																		className="fevcls"
																		title=''
																		onClick={() => handleFavorite(row.id)}
																	>
																		<i
																			className="fa-regular fa-heart" aria-hidden="true"	></i>
																	</a>
																	)
																}

															</div>

														</div>

													))}


												</div>

											)}


											{catFreeAds.length > 0 && (
												<div id="freeads">
													{catFreeAds.map((row, index) => (
														<div key={index} className={index % 2 == 0 ? 'section-listing bg-even' : 'section-listing bg-odd'}>
															<div className="main-item">
																<div className="fast-block">
																	{row.addImg ? (
																		<img src={config.apiUrl + row.addImg} alt={row.title} />
																	) : (
																		<img src={config.baseUrl + "img/noImage.png"} alt={row.title + row.id} />
																	)}

																</div>
																<div className="second-block">
																	<Link className="fordhead" to="javascript:;" onClick={() => handleAdUrl(row)}>
																		{row.title}
																	</Link>
																	{row.sell_price != 0 && row.sell_price != '' && parseInt(catDetails?.parent_id) != parseInt(config.cat_eat) ? (
																		<>
																			<p><span><i className="fa-solid fa-dollar-sign"></i></span> ${row.sell_price}</p>
																		</>
																	) : ''
																	}

																	{parseInt(catDetails?.parent_id) == parseInt(config.cat_per) ? (
																		<>
																			<p><span><i class="fa-solid fa-user"></i></span>Age - Gender: {row.age} - {row.sex == 0 ? 'Male' : 'Female'}</p>
																		</>
																	) : parseInt(catDetails?.parent_id) == parseInt(config.cat_real) ? (
																		<>

																			<p><span><i class="fa-regular fa-building"></i></span>Property Type: {row.property_type}</p>
																			<p><span><i class="fa-solid fa-bed"></i></span>Bedrooms: {row.bed_rooms}</p>

																		</>
																	) : parseInt(catDetails?.parent_id) == parseInt(config.cat_whats) ? (
																		<>
																			<p><span><i class="fa-solid fa-calendar-days"></i></span>Date: {row.formatted_whatson_date}</p>
																			<p><span><i class="fa-regular fa-clock"></i></span>Time: {row.time_whatson}</p>

																		</>
																	) : parseInt(catDetails?.parent_id) == parseInt(config.cat_jobs) ? (
																		<>
																			<p><span><i class="fa-solid fa-briefcase"></i></span>Work type: {row.job_work_type}</p>

																		</>
																	) : (
																		''
																	)}

																	<p><span><i className="fa-solid fa-location-dot"></i></span> {row.suburb} ({row.area})</p>
																</div>
																{
																	getfavAds.includes(row.id) ?
																		(<a
																			className="fevcls"
																			title=""
																			onClick={() => handleFavorite(row.id)}
																		>
																			<i className="fa fa-heart" aria-hidden="true"></i>
																		</a>)
																		:
																		(<a
																			className="fevcls"
																			title=''
																			onClick={() => handleFavorite(row.id)}
																		>
																			<i
																				className="fa-regular fa-heart" aria-hidden="true"	></i>
																		</a>)
																}

															</div>

														</div>

													))}
												</div>
											)}
											{totalPages > 0 ?
												<CustomPagination totalRecords={totalRecords} totalPages={totalPages} pageNeighbours={page} onPageChanged={onPageChanged} />
												: ''
											}

											{catFreeAds.length <= 0 && catPreAds.length <= 0 && (
												<div>
													<p><strong>No ads found.</strong></p>
												</div>
											)}
										</div>
									</>
								) : (
									<div className="buy-end-sell">
										<div className="loaderDiv">
											<img className="loaderImg" src={config.baseUrl + "img/loader.gif"} height={50} width={50} />
										</div>
									</div>
								)}
						</div>

						<div className="col-lg-3">
							<Rightnav />
						</div>
					</div>
				</div>
			</div>



			<Footer />
		</>
	)
}

export default Adslist;
