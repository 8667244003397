const config = {
    baseUrl: '/',
    // apiUrl: "http://localhost:3008/api/",
    // imageUrl: 'http://localhost:3008/api/uploads/',
	// bannersUrl: 'http://localhost:3008/api/banners/',
    // domainImg:'http://localhost:3008/api/domains/',
    // mail_images:'http://localhost:3008/api/mail_images/',
    apiUrl: "https://bondi.lyonsdemo.in/apis/api/",
    imageUrl: 'https://bondi.lyonsdemo.in/apis/api/uploads/',
    bannersUrl: 'https://bondi.lyonsdemo.in/apis/api/banners/',
    domainImg: 'https://bondi.lyonsdemo.in/apis/api/domains/',
    mail_images:'https://bondi.lyonsdemo.in/apis/api/mail_images/',
    site_key: "6Lec2ZAqAAAAAHrzW7M2zJMe4OblokTrmb-_SNPB",
    secret_key: "6Lec2ZAqAAAAAJWTYmamHcvvQZRAXp0Y4-sSQqoZ",
    superAdmin: 1,
    cat_per: 1,
    cat_real: 2,
    cat_buy: 3,
    cat_whats: 4,
    cat_jobs: 5,
    cat_eat: 6,
    cat_business: 7,
    paging_size: 25
}
export default config;
