import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config'
import toast, { Toaster } from 'react-hot-toast';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie'
import live from '../Assets/live.gif'
import popup_1 from '../Assets/popup_logo1.jpg'
import popup_2 from '../Assets/popup_logo3.jpg'
import popup_3 from '../Assets/popup_logo4.jpg'
import popup_4 from '../Assets/popup_logo7.jpg'
import popup_5 from '../Assets/popup_logo10.jpg'
import popup_6 from '../Assets/popup_logo12.jpg'
import { getCategoriesAction, getnavChildCategoriesAction, getStatesAction, getSearchSuburbAction, getSearchAdtitleAction, getMemberProfilesAction, updateMemberProfileAction, subscribemailAction, gettingDomainImgAction, getdomainContentAction, getDoaminStyleAction,getdomaincatdiscriptionAction } from '../Action/action'
const Domainid = (!Cookies.get('domain_id')) ? [] : JSON.parse(Cookies.get('domain_id'));
const Domainstyle = (!Cookies.get('domainstyle')) ? [] : JSON.parse(Cookies.get('domainstyle'));

const Header = () => {
	
	// style
	const timeColor = `#${Domainstyle[0]?.time_color}`;
	const headerButtonColor = `#${Domainstyle[0]?.header_buttoncolor}`;
	const headerMenuColor = `#${Domainstyle[0]?.header_menu}`;
	const headerMenuHoverColor = `#${Domainstyle[0]?.header_menu_hover}`;
	const header_search_text = `#${Domainstyle[0]?.header_search_text}`
	// style end
	const loginData = (!Cookies.get('loginSuccessMember')) ? [] : JSON.parse(Cookies.get('loginSuccessMember'));
	const [memeberDetails, setmemeberDetails] = useState({});
	const [categoriesData, setCategoriesData] = useState({});
	const [allchildCategory, setallchildCategory] = useState({});
	const [navchildCategory, setnavchildCategory] = useState({});
	const [statesData, setStatesData] = useState({});
	const [SuburbsearchResults, setSuburbsearchResults] = useState({});
	const [AdsearchResults, setAdsearchResults] = useState({});
	const [CategorysearchResults, setCategorysearchResults] = useState({});
	const [CategoryChilds, setCategoryChilds] = useState({});
	const [suburb, setsuburb] = useState('');
	const [adtitle, setadtitle] = useState('');
	const [categorytitle, setcategory] = useState('');
	const [catUrl, setCatUrl] = useState('');
	const [viewAll, setViewAll] = useState('');
	const [searchspinloader, setsearchspinloader] = useState(0);
	const [searchdata, setsearchdata] = useState({
		adtitle: '',
		cattitle: 'cat',
		state: 'all',
		suburb: 'suburb'
	})
	const [form, setForm] = useState({ email: '' })
	const [validatioError, setvalidatioError] = useState({});
	const currentDate = new Date().toDateString("en-US", { timeZone: "Australia/Sydney" });
	const currentTime = new Date().toLocaleString("en-US", { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: "Australia/Sydney" });
	const [Style, setStyle] = useState([])
	const [image, setimage] = useState([])
	const [hover, setHover] = useState(false);
	const [hovered,setHovered]= useState(0)	
	
	useEffect(() => {
		getStatesData();
		getCategoriesData();
		getCategoriesChildsData();
		if (loginData?.id != '' && loginData?.id != undefined) {
			getMemberProfileAPI();
		}
		// getimagedomainApi()
		const cookieData = Cookies.get('domain_id');
		if (cookieData) {
			const { data } = JSON.parse(cookieData);
			setimage(data)
		}

		const storedStyle = Cookies.get('domainstyle');
		if (storedStyle) {
			setStyle(JSON.parse(storedStyle));
		} else {
			setStyle({});
		}
	}, [])
	
	
	const getMemberProfileAPI = async () => {
		let res = await getMemberProfilesAction({
			id: loginData?.id,
			email: loginData?.email,
		});
		if (res.success) {
			setmemeberDetails(res.data);
		}
	};
	const getCategoriesData = async () => {
		let res = await getCategoriesAction({ domain_id: Domainid?.domain_id });
		if (res.success) {
			setCategoriesData(res.data);
		}
	};
	const getCategoriesChildsData = async () => {
		let res = await getnavChildCategoriesAction({ domain_id: Domainid?.domain_id });
		if (res.success) {
			setallchildCategory(res.data);
		}

	};

	const getStatesData = async () => {
		let res = await getStatesAction({ domain_id: Domainid.domain_id });
		if (res.success) {
			setStatesData(res.data);


		}
	};

	const allCategories = async (str) => {
		try {
			setcategory(str);
			let res = await getCategoriesAction();
			setCategorysearchResults(res.data);
			setsearchdata((old) => {
				return { ...old, 'cattitle': str };
			});
		}
		catch (error) {
			console.error(error);
		}
	};

	const getnavchildCategory = async (id) => {
		if (id != '') {
			const result = allchildCategory.filter(data => String(data.parent_id).includes(id));
			setnavchildCategory(result);

		}
		else {
			setnavchildCategory({});
		}
	};

	const SearchSuburb = async (str) => {
		try {
			setsuburb(str);
			if (str != '') {
				let res = await getSearchSuburbAction({ str: str, domain_id: Domainid.domain_id });
				setSuburbsearchResults(res.data);


				setsearchdata((old) => {
					return { ...old, 'suburb': str };
				});
			}
			else {
				setSuburbsearchResults('');
			}
		}
		catch (error) {
			console.error(error);
		}
	};

	const SearchAd = async (str) => {
		setsearchspinloader(1);
		try {
			setadtitle(str);
			if (str != '' && str.length > 2) {
				let res = await getSearchAdtitleAction({ str: str });
				setAdsearchResults(res.data);
				setsearchspinloader(0);
			}
			else {
				setAdsearchResults('');
				setsearchspinloader(0);
			}
		}
		catch (error) {
			console.error(error);
		}
	};

	const categoryChilds = async (id) => {
		try {
			if (id != '') {
				const result = allchildCategory.filter(data => String(data.parent_id).includes(id));
				setCategoryChilds(result);
			}
			else {
				setCategoryChilds('');
			}
		}
		catch (error) {
			console.error(error);
		}
	};

	const handleSelectCategory = (row) => {

		setcategory(row);

		setsearchdata((old) => {
			return { ...old, 'cattitle': row };
		});

		setCategorysearchResults({});
	};

	const handleSelectSuburb = (row) => {
		setsuburb(row);
		setsearchdata((old) => {
			return { ...old, 'suburb': row };
		});

		setSuburbsearchResults({});
	};

	const handleSelectAd = (row) => {
		setadtitle(row);

		setsearchdata((old) => {
			return { ...old, 'adtitle': row };
		});

		setAdsearchResults({});
	};

	const handleAdState = async (val) => {
		setsearchdata((old) => {
			return { ...old, 'state': val };
		});
	}

	const handleCatUrl = (row) => {
		var pagenewUrl = '';
		pagenewUrl = config.baseUrl + 'cat/' + row.cat_id + '/' + row.parent_cat.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();
		window.location.href = `${pagenewUrl}`;
	};

	const getcatUrl = (row) => {
		var pagenewUrl = '';
		pagenewUrl = config.baseUrl + 'cat/' + row.cat_id + '/' + row.parent_cat.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase() + '-' + row.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();
		setCatUrl(pagenewUrl);
	};

	const handleViewAll = async(row) => {
		var pagenewUrl = '';
		pagenewUrl = config.baseUrl + 'cat/' + row.cat_id + '/action/all/' + row.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();
		
		window.location.href = `${pagenewUrl}`;
	};

	const getviewAll = (row) => {
		var pagenewUrl = '';
		pagenewUrl = config.baseUrl + 'cat/' + row.cat_id + '/action/all/' + row.cat_name.replace(/ /g, "-").replace(/'/g, "").replace(/&/g, "and").replace(/[^A-Za-z0-9\-\']/g, '').toLowerCase();
		
		setViewAll(pagenewUrl);
	};
	
	const logout = async () => {
		Cookies.remove("loginSuccessMember");
		window.location.href = config.baseUrl;
	};

	const findAd = async (e) => {
		e.preventDefault()

		if (searchdata.adtitle != '') {
			var pagenewUrl = '';
			pagenewUrl = config.baseUrl + 'search/' + searchdata.adtitle + '/' + searchdata.cattitle + '/' + searchdata.state + '/' + searchdata.suburb
			window.location.href = `${pagenewUrl}`;
		}

	}
	const handleBlur = (e) => {
		// Set a timeout for 5 seconds (5000 milliseconds)
		setTimeout(() => {
			setCategorysearchResults('');
		}, 2000);
	};

	const Subscribe = async () => {
		let mes = validate();

		if (!mes) {
			return false;
		}

		try {

			const res = await subscribemailAction({ email: form.email });

			if (res.success) {
				toast.success(res.msg);
			} else {
				toast.error(res.msg);
			}
		} catch (error) {
			toast.error('An error occurred. Please try again.');
			console.error('Subscription error:', error);
		}
	};
	//   handle input
	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})

	}

	function validate() {
		let emailError = "";

		if (form.email === '') {
			emailError = "Email is required."
		}
		if (emailError) {
			setvalidatioError({
				emailError
			});
			return false;
		} else {
			setvalidatioError({
				emailError
			});
			return true;
		}
	}

	const getDomainContentApi = async (id) => {
		try {
			let res = await getdomainContentAction({ doamin_id: id });
			if (res.success) {
				setTimeout(() => {
					window.location.href = `${config.baseUrl}`;
				}, 1000);
				const cookieData = {
					domain_id: id,
					data: res.data
				};

				Cookies.set('domain_id', JSON.stringify(cookieData));
				DomainStyle(id)
				

			}
		} catch (error) {
			console.error('Error fetching domain content:', error);

		}
	};
	const DomainStyle = async (id) => {
		if (id == 0) {
			Cookies.remove('domain_id')
			Cookies.remove('domainstyle');
			setStyle({});
		}
		else {
			let res = await getDoaminStyleAction({ domain_id: id });
			if (res.success) {
				setStyle(res.data);
				Cookies.set('domainstyle', JSON.stringify(res.data));
			}
		}
	};

	return (
		<>
		<Helmet>
				<title>{image[0]?.main_title}</title>
				<meta
					name="description"
					content={image[0]?.domain_meta_desc}
				/>
				<meta
					name="keywords"
					content={image[0]?.domain_meta_keywords}
				/>
			</Helmet>
			<Toaster />
			<div className="top-header-logo">
				<div className="container">
					<div className="row">
						<div className="col-lg-5 col-md-4">
							<div className="logo-section">
								<Link to={config.baseUrl}>
									{
										image.length > 0 ? (
											<img src={config.domainImg + image[0]?.domain_img} alt='img' />
										) : (

											!document.cookie.includes("domain_id") ? (
												<img src={config.baseUrl + "img/bondinewBigFlag.png"} alt='img' />
											) : ""
										)
									}

								</Link>
							</div>
						</div>
						<div className="col-lg-7 col-md-8">
							<div className="search-box">
								<div className="login-contant">
									<span class="blink" >Network</span>
									<button type="button" id='popup' data-bs-toggle="modal" data-bs-target="#staticBackdrop">
										<i id="dot" className="fa-solid fa-location-dot dot"></i>&nbsp; {image.length > 0 ? image[0]?.main_title : 'BondiClassified'}
									</button>
									<img id='live' src={live} />
									<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
										<div class="modal-dialog" >
											<div class="modal-content" id="model_backgrond">

												<button type="button" id='closes' data-bs-dismiss="modal" >X</button>

												<div id="model_img">
													<div className="img-container">
														<a>
															<img className='popup_img'
																src={popup_1}
																onClick={() => getDomainContentApi(0)}

															/>
														</a>
														<a >
															<img className='popup_img'
																src={popup_3}
																onClick={() => getDomainContentApi(20)}

															/>
														</a>
														<a >
															<img className='popup_img'
																src={popup_2}
																onClick={() => getDomainContentApi(21)}

															/>
														</a>

														<a >
															<img className='popup_img'
																src={popup_4}
																onClick={() => getDomainContentApi(22)}

															/>
														</a>
														<a >
															<img className='popup_img'
																src={popup_5}
																onClick={() => getDomainContentApi(24)}

															/>
														</a>
														<a >
															<img className='popup_img'
																src={popup_6}
																onClick={() => getDomainContentApi(0)}
															/>
														</a>
													</div>

												</div>
											</div>
										</div>
									</div>

									<Link to="" style={{ color: timeColor }}><span ><i className="fa-solid fa-location-dot" style={{ background: headerButtonColor }}></i></span> SYDNEY | {currentDate + ' ' + currentTime}</Link>
								</div>


								<div className="loginbtn">
									<div className="btnauser">
										{!loginData || loginData == '' ?
											<>
												<Link to={config.baseUrl + 'login'} style={{ background: headerButtonColor }}><span><i className="fa-solid fa-arrow-right-to-bracket"></i></span>Login</Link>
												<Link className="btnbuser" to={config.baseUrl + 'register'} style={{ background: headerButtonColor }}><span><i className="fa-solid fa-user"></i></span>New User</Link>
											</>
											:
											<>
												<Link className="btnbuser" to={config.baseUrl + 'profile-update'} style={{ background: headerButtonColor }}><span><i className="fa-solid fa-user"></i></span>{loginData?.fname} {loginData?.lname} {memeberDetails?.isVIP ? ' (VIP Mem) ' + memeberDetails?.daysLeft + ' days left' : ' (Basic Member)'}</Link>
												&nbsp;&nbsp;<Link to="" onClick={logout} style={{ background: headerButtonColor }} ><span><i className="fa-solid fa-arrow-right-to-bracket"   ></i></span>Logout</Link>

											</>
										}

									</div>
									<div className="icon-socialmedia">
										<div className="facebook">
											<Link to="https://www.facebook.com/BondiClassifieds.com.au" target="_blank"><i className="fa-brands fa-facebook-f"></i></Link>
										</div>
										<div className="facebook instagram">
											<Link to="https://www.instagram.com/bondiclassifieds.com.au" target="_blank"><i className="fa-brands fa-instagram"></i></Link>
										</div>
										<div className="facebook twiter">
											<Link to="https://x.com/BondiClassified" target="_blank"><i className="fa-solid fa-x"></i></Link>
										</div>
										<div className="facebook linkdin">
											<Link to="https://www.linkedin.com/showcase/bondiclassifieds/about/" target="_blank"><i className="fa-brands fa-linkedin-in"></i></Link>
										</div>
									</div>
								</div>
								<div className="heading-hero">
									<h2 style={{ color: headerMenuColor }}>Search 1000's of online AD's</h2>
								</div>
								<div className="online-ads">
									<input type="email" id="email" name="email" value={form.email} className="subscribertxtbx" placeholder="Enter your email" onChange={inputHandler} />

									<button type='button' className='ml-1' onClick={Subscribe} style={{ background: headerButtonColor }}><span><i className="fa-solid fa-paper-plane"></i></span>Subscribe</button>
									<span className="text-danger" style={{ display: 'block' }}>{validatioError.emailError}</span>
									<div className="subscribemsg"> ** Subscribe for a chance to Win Prizes </div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="all-categories pdtb-50">
				<div className="container">
					<div className="form-section">
						<form onSubmit={findAd}>
							<div className="form-row align-items-center">
								<div className="col-lg-1">
									<div className="textfor" >
										<p style={{ color: header_search_text }}>Search For</p>
									</div>
								</div>
								<div className="form-group col-lg-2 col-sm-6">
									<input type="text" className="form-control" id="filter_adtitle" placeholder="find a.." onChange={(e) => SearchAd(e.target.value)} value={adtitle} autocomplete="off" />
									{searchspinloader == 1 ? (<img className="loaderImg search-loader" src={config.baseUrl + "img/loader.gif"} height={20} width={20} />) : ''}
									{AdsearchResults.length > 0 ? (
										<ul className="adsList">
											{AdsearchResults.map((row, index) => (
												<li key={index} onClick={() => handleSelectAd(row)}>
													{row}
												</li>
											))}
										</ul>
									) : null}
								</div>
								<div className="form-group col-lg-2 col-sm-6">
									<input type="text" className="form-control" id="filter_adcategories" placeholder="All Categories" onFocus={(e) => allCategories(e.target.value)} onBlur={handleBlur} value={categorytitle} autocomplete="off" />

									{CategorysearchResults.length > 0 ? (
										<ul className="catsList">
											{CategorysearchResults.map((row, index) => (
												row.countChild > 0 ?
													<li key={index}>
														<Link to="javascript:;" onClick={() => handleSelectCategory(row.cat_name)} onMouseOver={() => categoryChilds(row.cat_id)} onMouseOut={() => categoryChilds(row.cat_id)} >{row.cat_name}</Link>
														{CategoryChilds.length > 0 ? (
															<ul className="catschildList">
																{CategoryChilds.map((childrow, index) => (
																	childrow.parent_id == row.cat_id ?
																		<li to="javascript:;" key={index} onClick={() => handleSelectCategory(childrow.cat_name)} >
																			{childrow.cat_name}
																		</li>
																		: null
																))}
															</ul>
														) : null}
													</li>
													: ''
											))}
										</ul>
									) : null}
								</div>
								<div className="form-group col-lg-2 col-sm-6">
									<select className="form-control" id="filter_adstates" onChange={(e) => handleAdState(e.target.value)}>
										<option value="all">Search In All States</option>
										{statesData.length > 0 ?
											statesData.map((state, index) => {
												return (
													<option key={index} value={state.state}>{state.state}</option>
												)
											})
											: ''
										}
									</select>
								</div>

								<div className="form-group col-lg-2 col-sm-6">
									<input type="text" className="form-control" onChange={(e) => SearchSuburb(e.target.value)} id="filter_adsuburb" placeholder="Search by suburb.." value={suburb} autocomplete="off" />
									{SuburbsearchResults.length > 0 ? (
										<ul className="suburbList">
											{SuburbsearchResults.map((row, index) => (
												<li key={index} onClick={() => handleSelectSuburb(row.rowlocation)}>
													{row.rowlocation}
												</li>
											))}
										</ul>
									) : null}
								</div>
								<div className="col-md-3">
									<div className="submit-btn">
										<button type='submit' style={{ background: headerButtonColor }}>Find</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			<div className="mega-menu pdtb-20">
				<div className="container">
					<nav className="navbar navbar-expand-xl " style={{ background: headerMenuColor }}>
						<Link className="navbar-brand" to="">menu</Link>
						<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span><i className="fa-solid fa-bars"></i></span>
						</button>

						<div className="collapse navbar-collapse" id="navbarSupportedContent">
							<ul className="navbar-nav letest-section mt-auto" id="navbar-nav-ul" >
								<li 
								className="nav-item" id='nav-hover'
								style={{
									cursor: 'pointer',
									backgroundColor: hovered === 1  ? headerMenuHoverColor : headerMenuColor,
								}}
								onMouseEnter={() => setHovered(1)}
								onMouseLeave={() => setHovered(0)}  >
									<Link className="nav-link" to={config.baseUrl} >LATEST</Link>
								</li>

								{categoriesData.length > 0 ? (
									categoriesData.map((row, index) => (
										row.countChild > 0 ?
											<li key={index} className="nav-item dropdown position-static"
												style={{
													cursor: 'pointer',
													backgroundColor: hover === index ? headerMenuHoverColor : headerMenuColor,
												}}
												onMouseEnter={() => setHover(index)}
												onMouseLeave={() => setHover(null)}>
												<Link className="nav-link dropdown-toggle" data-toggle="dropdown" to="javascript:;" id="navbarDropdown" role="button" onFocus={() => getnavchildCategory(row.cat_id)}   >{row.cat_name}</Link>

												<ul className="dropdown-menu megamenu" >
													<div className="navquicklink" style={{ background: headerMenuHoverColor }}>
														<ul className='row'>
															<>
																{navchildCategory.length > 0 ? (
																	navchildCategory.map((rowchild, index) => (
																		rowchild.parent_id === row.cat_id ? (
																			<li key={index} className='col-md-3 col-sm-6'
																				style={{
																					cursor: 'pointer',
																					backgroundColor: hover == rowchild ? headerMenuColor : headerMenuHoverColor,
																				}}
																				onMouseEnter={() => setHover(rowchild)}
																				onMouseLeave={() => setHover(null)}
																			>
																				<Link to="javascript:;" onClick={() => handleCatUrl(rowchild)}>
																					{rowchild.cat_name}
																					<b> ({rowchild.ads})</b>
																				</Link>
																			</li>
																		) : null
																	))
																) : (
																	<li>No categories available</li>
																)}
																<li key="viewAll" className="col-md-3 col-sm-6"
																 style={{
																	cursor: 'pointer',
																	backgroundColor: hover == 2  ? headerMenuColor : headerMenuHoverColor,
																}}
																onMouseEnter={() => setHover(2)}
																onMouseLeave={() => setHover(0)} 
																>
																	<Link to="javascript:;" onClick={() => handleViewAll(row)}
																	
																	> View All </Link>
																</li>
															</>
														</ul>
													</div>

												</ul>
											</li>

											: ''
									))
								) : null}

							</ul>
						</div>
					</nav>
				</div>
			</div>
		</>
	)
}
export default Header;
